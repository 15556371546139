
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import usefulTrainingApi from "@/core/services/UsefulTraining";
import { Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "administration-usefultraining",
    components: { Field },
    data: function () {
        return {
            usefulTraining: {
                id: null,
                label: "",
                link: "",
            },
            usefulTrainings: [],
        };
    },
    methods: {
        addUsefulTraning() {
            buttonHandle.handleWaitingButton(this.submitButton);
            if (this.usefulTraining.id) {
                usefulTrainingApi.updateUsefulTraining(this.usefulTraining.id, this.usefulTraining).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                });
            } else {
                usefulTrainingApi.addUsefulTraining(this.usefulTraining).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                });
            }
        },
        deleteUsefulTraining(usefulTraining) {
            usefulTrainingApi.deleteUsefulTraining(usefulTraining.pk).then(() => {
                location.reload();
            });
        },
        initEdit(usefulTraining) {
            this.usefulTraining = {
                id: usefulTraining.pk,
                label: usefulTraining.label,
                link: usefulTraining.link,
            };
        },
        initAdd() {
            this.usefulTraining = {
                id: null,
                label: "",
                link: "",
            };
        },
        getUsefulTraining() {
            usefulTrainingApi.getUsefulTraining().then((response) => {
                this.usefulTrainings = response.data["usefultrainings"];
            });
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.usefulTraining.management"), []);
        const submitButton = ref<HTMLElement | null>(null);
        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.usefulTraining.management"), []);
        },
    },
    created() {
        this.getUsefulTraining();
    },
});
